import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  consumableName: '',
  consumableItemName: '',
  consumableItem: {},
}

const consumableSlice = createSlice({
  name: 'consumable',
  initialState,
  reducers: {
    setConsumableName: (state, { payload }) => {
      state.consumableName = payload
    },
    setConsumableItemName: (state, { payload }) => {
      state.consumableItemName = payload
    },
    setConsumableItem: (state, { payload }) => {
      state.consumableItem = payload
    },
  },
})

export const getConsumableName = (state) => state.consumable.consumableName
export const getConsumableItemName = (state) =>
  state.consumable.consumableItemName
export const getConsumableItem = (state) => state.consumable.consumableItem

export const { setConsumableName, setConsumableItemName, setConsumableItem } =
  consumableSlice.actions
export const consumable = consumableSlice.reducer
