import { createSlice } from '@reduxjs/toolkit'

const addUserInitialState = {
  contract: {},
  education: {},
  documents: [],
  assets: [],
  payments: [],
  credentials: {},
}

const addUserSlice = createSlice({
  name: 'addUserForm',
  initialState: {
    formData: {
      contract: {},
      education: {},
      documents: [],
      assets: [],
      payments: [],
      credentials: {},
    },
    step: 1,
  },
  reducers: {
    updateAddUserForm: (state, action) => {
      const { formKey, data, step } = action.payload

      if (
        formKey === 'documents' ||
        formKey === 'assets' ||
        formKey === 'payments'
      ) {
        state.formData[formKey] = [...data]
      } else if (formKey) {
        // Update a specific section inside formData
        state.formData[formKey] = { ...state.formData[formKey], ...data }
      } else {
        // Add data directly to the formData object
        state.formData = { ...state.formData, ...data }
      }

      if (step !== undefined) {
        state.step = step
      }
    },
    updateAddUserStep: (state, action) => {
      state.step = action.payload
    },
    removeProfilePicture: (state) => {
      state.formData.profilePicture = null
    },
    resetFormData: (state) => {
      state.formData = addUserInitialState
      state.step = 1
    },
  },
})

const addEmployeeSlice = createSlice({
  name: `addEmployee`,
  initialState: {
    address: {
      country: `Pakistan`,
      province: `Punjab`,
    },
    emergencyContact: [{ name: ``, relation: ``, phoneNumber: `` }],
    contract: { salaryType: `MONTHLY`, monthsToNextRaise: 6 },
  },
  reducers: {
    addEmployeeData: (state, action) => {
      return { ...action.payload }
      // }
    },
  },
})

const displayEmployeeSlice = createSlice({
  name: `displayEmployee`,
  initialState: {},
  reducers: {
    displayEmployeeData: (state, action) => {
      return { ...action.payload }
    },
  },
})

const currentDate = new Date()
const currentMonth = currentDate.getMonth()
const currentYear = currentDate.getFullYear()

const FilterPayrollSlice = createSlice({
  name: `filterPayroll`,
  initialState: {
    userId: ``,
    status: ``,
    month: currentMonth,
    year: currentYear,
  },
  reducers: {
    applyFilteronPayroll: (state, action) => {
      return { ...action.payload }
    },
  },
})

const FilterRequestsSlice = createSlice({
  name: `filterRequests`,
  initialState: {
    pmApproval: `false`,
    hrApproval: `false`,
    tlApproval: `false`,
    month: ``,
    year: ``,
  },
  reducers: {
    applyFilteronRequests: (state, action) => {
      return action.payload
    },
  },
})

const loggedInUserSlice = createSlice({
  name: `loggedInUser`,
  initialState: {
    name: ``,
    systemRole: ``,
    picture: ``,
  },
  reducers: {
    applyUser: (state, action) => {
      return { ...action.payload }
    },
  },
})

const requestChangeSlice = createSlice({
  name: `requestChange`,
  initialState: {
    requestChange: `false`,
  },
  reducers: {
    applyRequestChange: (state, action) => {
      return { ...action.payload }
    },
  },
})

const attendanceFilterCurrentDate = new Date()
attendanceFilterCurrentDate.setDate(attendanceFilterCurrentDate.getDate() - 1)

let attendanceFilterCurrentMonth
let attendanceFilterCurrentYear
// if (currentDate.getMonth() + 1 === 1) {
//   attendanceFilterCurrentMonth = 12
//   attendanceFilterCurrentYear = currentDate.getFullYear() - 1
// } else {
//   attendanceFilterCurrentMonth = String(currentDate.getMonth() + 1).padStart(
//     2,
//     `0`,
//   )
//   attendanceFilterCurrentYear = currentDate.getFullYear()
// }

attendanceFilterCurrentMonth = String(currentDate.getMonth() + 1).padStart(
  2,
  `0`,
)
attendanceFilterCurrentYear = currentDate.getFullYear()

const attendanceFilterCurrentDay = String(currentDate.getDate()).padStart(
  2,
  `0`,
)

const filterAttendanceSlice = createSlice({
  name: `filterAttendance`,
  initialState: {
    attendanceLog: `Monthly`,
    Date: `${attendanceFilterCurrentYear}-${attendanceFilterCurrentMonth}-${attendanceFilterCurrentDay}`,
    monthYear: `${attendanceFilterCurrentYear}-${attendanceFilterCurrentMonth}`,
  },
  reducers: {
    applyAttendanceFilter: (state, action) => {
      return { ...action.payload }
    },
  },
})

const requestsCountSlice = createSlice({
  name: `requestsCount`,
  initialState: {},
  reducers: {
    applyRequestsCount: (state, action) => {
      return { ...action.payload }
    },
  },
})

const monthlyIndividualSlice = createSlice({
  name: `monthlyIndividual`,
  initialState: {
    monthYear: `${attendanceFilterCurrentYear}-${attendanceFilterCurrentMonth}`,
  },
  reducers: {
    applyMonthlyIndividual: (state, action) => {
      return { ...action.payload }
    },
  },
})

const dailyAttendanceDateSliderSlice = createSlice({
  name: `dailyAttendanceDateSlider`,
  initialState: {
    dateMonthYear: ``,
  },
  reducers: {
    applyDailyAttendanceDateSlider: (state, action) => {
      return { ...action.payload }
    },
  },
})

const monthlyAttendanceDateSliderSlice = createSlice({
  name: `monthlyAttendanceDateSlider`,
  initialState: {
    monthYear: ``,
  },
  reducers: {
    applyMonthlyAttendanceDateSlider: (state, action) => {
      return { ...action.payload }
    },
  },
})

const colorsSlice = createSlice({
  name: `colors`,
  initialState: {
    appColor: `rgba(96, 57, 187, 1)`,
  },
  reducers: {
    applyColors: (state, action) => {
      return { ...action.payload }
    },
  },
})

const activatedQuotationSlice = createSlice({
  name: `activatedQuotation`,
  initialState: {
    quoteId: ``,
  },
  reducers: {
    activateQuotation: (state, action) => {
      return { ...action.payload }
    },
  },
})

const configurationSlice = createSlice({
  name: `configuration`,
  initialState: {
    image: ``,
  },
  reducers: {
    setConfiguration: (state, action) => {
      return { ...action.payload }
    },
  },
})

export const { addEmployeeData } = addEmployeeSlice.actions
export const { displayEmployeeData } = displayEmployeeSlice.actions
export const { applyFilteronPayroll } = FilterPayrollSlice.actions
export const { applyFilteronRequests } = FilterRequestsSlice.actions
export const { applyUser } = loggedInUserSlice.actions
export const { applyRequestChange } = requestChangeSlice.actions
export const { applyAttendanceFilter } = filterAttendanceSlice.actions
export const { applyDailyAttendanceDateSlider } =
  dailyAttendanceDateSliderSlice.actions

export const { applyMonthlyIndividual } = monthlyIndividualSlice.actions

export const { applyMonthlyAttendanceDateSlider } =
  monthlyAttendanceDateSliderSlice.actions
export const { applyRequestsCount } = requestsCountSlice.actions

export const { applyColors } = colorsSlice.actions
export const { activateQuotation } = activatedQuotationSlice.actions

export const { setConfiguration } = configurationSlice.actions

export const {
  updateAddUserForm,
  resetFormData,
  updateAddUserStep,
  removeProfilePicture,
} = addUserSlice.actions

const addUserReducer = addUserSlice.reducer

const addEmployeeReducer = addEmployeeSlice.reducer
const displayEmployeeReducer = displayEmployeeSlice.reducer
const filterPayrollReducer = FilterPayrollSlice.reducer
const filterRequestsReducer = FilterRequestsSlice.reducer
const userReducer = loggedInUserSlice.reducer
const requestChangeReducer = requestChangeSlice.reducer
const filterAttendanceReducer = filterAttendanceSlice.reducer
const dailyAttendanceDateSliderReducer = dailyAttendanceDateSliderSlice.reducer
const monthlyAttendanceDateSliderReducer =
  monthlyAttendanceDateSliderSlice.reducer
const monthlyIndividualReducer = monthlyIndividualSlice.reducer
const requestCountReducer = requestsCountSlice.reducer
const colorsReducer = colorsSlice.reducer
const quotationActivationReducer = activatedQuotationSlice.reducer
const configurationReducer = configurationSlice.reducer

export {
  addEmployeeReducer,
  displayEmployeeReducer,
  filterPayrollReducer,
  filterRequestsReducer,
  userReducer,
  requestChangeReducer,
  filterAttendanceReducer,
  dailyAttendanceDateSliderReducer,
  monthlyAttendanceDateSliderReducer,
  monthlyIndividualReducer,
  requestCountReducer,
  colorsReducer,
  quotationActivationReducer,
  configurationReducer,
  addUserReducer,
}
