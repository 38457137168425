import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  itemsList: null,
  selectedItem: null,
  isOpenSubMenu: [],
}

const navigatorSlice = createSlice({
  name: 'navigator',
  initialState,
  reducers: {
    setItemList: (state, { payload }) => {
      state.itemsList = payload
    },
    setSelectedItem: (state, { payload }) => {
      state.selectedItem = payload
    },
    setIsOpenSubMenu: (state, { payload }) => {
      state.isOpenSubMenu = payload
    },
  },
})

export const getItemList = (state) => state.navigator.itemsList
export const getSelectedItem = (state) => state.navigator.selectedItem
export const getIsOpenSubMenu = (state) => state.navigator.isOpenSubMenu

export const { setItemList, setSelectedItem, setIsOpenSubMenu } =
  navigatorSlice.actions
export const navigator = navigatorSlice.reducer
