export const getRemarks = (points) => {
  if (points > 0 && points < 3) {
    return `Worst`
  } else if (points == 3) {
    return `Bad`
  } else if (points > 3 && points < 6) {
    return `Unsatisfactory`
  } else if (points > 5 && points < 8) {
    return `Average`
  } else if (points == 8) {
    return `Satisfactory`
  } else if (points == 9) {
    return `Good`
  } else if (points == 10) {
    return `Excellent`
  } else return null
}

export const pointsDif = (val1, val2) => {
  return val1 - val2
}

export const isValEmpty = (val) => {
  return val === null || val === undefined || val === `` ? true : false
}

export const isDisabled = (val, systemRole) => {
  return !isValEmpty(val) || (systemRole !== `EMPLOYEE` && systemRole !== `TR`)
    ? true
    : false
}

export const validateAndConvertToNumber = (value) => {
  if (value === null || value === undefined || value === ``) {
    return value
  }
  return Number(value)
}

//* Function to check if object all values of payload are valid

// Function to check if an individual value is valid
export function isValid(value) {
  return (
    value !== null &&
    value !== undefined &&
    value !== `` &&
    !(typeof value === `string` && value.trim() === ``)
  )
}

// Function to check if an object has any empty values
function hasEmptyValue(obj) {
  if (typeof obj !== `object` || obj === null) {
    return !isValid(obj)
  }

  if (Array.isArray(obj)) {
    if (obj.length === 0) {
      return true // Return true if array is empty
    }

    for (const item of obj) {
      if (typeof item === `object` && hasEmptyValue(item)) {
        return true
      } else if (!isValid(item)) {
        return true
      }
    }

    return false
  }

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      if (!isValid(obj[key])) {
        return true
      }
      if (typeof obj[key] === `object` && hasEmptyValue(obj[key])) {
        return true
      }
    }
  }
  return false
}

// Function to validate the payload
export const validatePayload = (payload) => {
  return !hasEmptyValue(payload)
}

//* -------------------------------------------------------

export const isEmptyValue = (value) => {
  return value === null || value === undefined || value === ``
}

//* Function to check if an object has no values

export const isEmptyObject = (obj) => {
  if (typeof obj !== `object` || obj === null) {
    return isEmptyValue(obj)
  }

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      if (!isEmptyValue(obj[key])) {
        if (typeof obj[key] === `object` && !isEmptyObject(obj[key])) {
          return false
        } else if (typeof obj[key] !== `object`) {
          return false
        }
      }
    }
  }

  return true
}

//* Compare todays date with a provided date

export const isLessThenToday = (providedDate) => {
  const today = new Date()
  const dateToCompare = new Date(providedDate)

  // Ensure both dates have no time part to compare only the dates
  today.setHours(0, 0, 0, 0)
  dateToCompare.setHours(0, 0, 0, 0)

  if (today < dateToCompare) {
    return true
  } else {
    return false
  }
}

//* Function to check if an object has a certain key

export function objectContainsKey(obj, key) {
  if (typeof obj !== `object` || obj === null) {
    return false // If the input is not an object or is null, return false
  }

  return Object.prototype.hasOwnProperty.call(obj, key)
}

//* Function to check if a provided string is an empty HTML tag or contains only whitespace
export const isEmptyHtmlTag = (str) => {
  //? Regular expression to match empty HTML tags or tags containing only whitespace
  const emptyHtmlTagPattern = /^<([a-z][a-z0-9]*)\b[^>]*>\s*<\/\1>$/i
  return emptyHtmlTagPattern.test(str?.trim())
}

//* FUNCTION TO ROUND OFF TO 2 DECIMALS
export const roundToTwoDecimals = (number) => {
  if (!number) return ''
  const parsed = parseFloat(number)
  return isNaN(parsed) ? '' : (Math.round(parsed * 100) / 100).toFixed(2)
}

//* Function to Genarate a timestamp
export const generateTimestamp = () => {
  const now = new Date()
  const year = now.getFullYear()
  const month = String(now.getMonth() + 1).padStart(2, `0`) // Months are zero-based
  const day = String(now.getDate()).padStart(2, `0`)
  const hours = String(now.getHours()).padStart(2, `0`)
  const minutes = String(now.getMinutes()).padStart(2, `0`)
  const seconds = String(now.getSeconds()).padStart(2, `0`)

  return `${year}-${month}-${day}_${hours}-${minutes}-${seconds}`
}

//$ ================================================================================ $//
//$                               GET SYSTEM ROLE
//$ ================================================================================ $//

//* Helper function to get the current role from local storage
export const getRole = () => {
  return localStorage.getItem(`systemRole`)
}

//* Helper functions to check specific roles
export const isCTO = () => getRole() === `CTO`
export const isCEO = () => getRole() === `CEO`
export const isHRM = () => getRole() === `HRM`
export const isHRExecutive = () => getRole() === `HR_EXECUTIVE`
export const isTR = () => getRole() === `TECHNICAL_RECRUITER`
export const isInternee = () => getRole() === `INTERNEE`
export const isAdminOfficer = () => getRole() === `ADMIN_OFFICER`
export const isEmployee = () =>
  getRole() === `EMPLOYEE` || getRole() === `TECHNICAL_RECRUITER`
export const isStaff = () => getRole() === `STAFF`

//* Helper Functions to check for management position
export const isManagement = () =>
  getRole() === `CTO` ||
  getRole() === `CEO` ||
  getRole() === `HRM` ||
  getRole() === `ADMIN_OFFICER`

//$ ================================================================================ $//
//$                            REMOVE NON VALID VALUES FORM OBJECT
//$ ================================================================================ $//

export const removeInvalidValues = (obj) => {
  // Create a new object to store valid values
  const cleanedObject = {}

  // Iterate over each key-value pair in the object
  for (const key in obj) {
    // Use Object.prototype.hasOwnProperty.call() for safety
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const value = obj[key]

      // Check if the value is valid (not "", undefined, or null)
      if (value !== '' && value !== undefined && value !== null) {
        cleanedObject[key] = value // Keep valid values
      }
    }
  }

  return cleanedObject
}

//$ ================================================================================ $//
//$                             REMOVE EMPTY OBJECTS OR ARRAYS FROM OBJECT
//$ ================================================================================ $//

export function cleanObject(obj) {
  // Helper function to check if an object is empty
  const isObjectEmpty = (val) =>
    typeof val === 'object' && val !== null && Object.keys(val).length === 0

  // Helper function to check if an array contains only empty objects
  const isArrayContainingEmptyObjects = (arr) =>
    Array.isArray(arr) && arr.every((item) => isObjectEmpty(cleanObject(item)))

  // Create a new object/array to avoid mutating the original one
  const newObj = Array.isArray(obj) ? [...obj] : { ...obj }

  // Iterate over object keys
  for (const key in newObj) {
    const value = newObj[key]

    // Recursively clean if value is an object
    if (typeof value === 'object' && value !== null) {
      newObj[key] = cleanObject(value)
    }

    // After cleaning, check if value is still an empty object, empty array, or array of empty objects
    if (
      isObjectEmpty(newObj[key]) ||
      (Array.isArray(newObj[key]) && newObj[key].length === 0) ||
      isArrayContainingEmptyObjects(newObj[key])
    ) {
      delete newObj[key]
    }
  }

  // If the current object or array becomes empty after cleaning, return an empty object or array
  if (Array.isArray(newObj) && newObj.length === 0) {
    return []
  } else if (isObjectEmpty(newObj)) {
    return {}
  }

  return newObj
}

//$ ================================================================================ $//
//$           FUNCTION TO FORMAT STRING FROM (START_DATE) TO (Start Date)
//$ ================================================================================ $//

export const formatToTitleCase = (str) => {
  return str
    .toLowerCase() // Convert entire string to lowercase
    .split('_') // Split by underscores
    .map(
      (
        word, // Capitalize the first letter of each word
      ) => word.charAt(0).toUpperCase() + word.slice(1),
    )
    .join(' ') // Join the words back with a space
}
