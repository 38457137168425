import React from 'react'
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min'
import Login from 'app/Pages/Login'
import { isStaff } from 'app/Utils/helper'

const ProtectedLogin = () => {
  const isLoggedIn = localStorage.getItem(`AuthToken`)
  return isLoggedIn && !isStaff() ? (
    <Redirect to="/dashboard" />
  ) : isLoggedIn && isStaff() ? (
    <Redirect to="/consumables" />
  ) : (
    <Login />
  )
}

export default ProtectedLogin
